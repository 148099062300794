// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  background-color: #f1f1f1;
  padding: 20px 0;
  text-align: center;
  background-color: var(--navy);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  padding: 0 8rem;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.footer-content p {
  margin: 0;
  color: var(--lightest-slate);
  text-align: center;
}

.social-links {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  text-align: center;
}

.social-links li {
  display: inline;
  margin: 0 10px;
}

.social-links a {
  text-decoration: none;
  color: #007bff;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/footer.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,6BAA6B;EAC7B,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,eAAe;EACf,WAAW;EACX,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,4BAA4B;EAC5B,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":["footer {\n  background-color: #f1f1f1;\n  padding: 20px 0;\n  text-align: center;\n  background-color: var(--navy);\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 10rem;\n  padding: 0 8rem;\n  position: fixed;\n  width: 100%;\n  z-index: 1000;\n}\n\n.footer-container {\n  max-width: 1200px;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.footer-content p {\n  margin: 0;\n  color: var(--lightest-slate);\n  text-align: center;\n}\n\n.social-links {\n  list-style-type: none;\n  padding: 0;\n  margin: 10px 0;\n  text-align: center;\n}\n\n.social-links li {\n  display: inline;\n  margin: 0 10px;\n}\n\n.social-links a {\n  text-decoration: none;\n  color: #007bff;\n  transition: color 0.3s ease;\n}\n\n.social-links a:hover {\n  color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import rk from "./img/rk.jpg";
import rkr from "./img/rkr.png";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import "./css/socialmedia.css";
import "font-awesome/css/font-awesome.min.css";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <section id="about" className="full_screen">
        <div className="about_container">
          <div className="content_container">
            <div className="about_content">
              <h2>
                <span>01.</span>About
              </h2>
              <br />

              <p>
                Hello! I'm Rakib,Currently working as a Backend Developer(Devsstream Limited). I have completed BSC from{" "}
                <span>Daffodl International University.</span>
                <br />
                <br />
                I specialize in creating seamless digital solutions, from websites and blogs to applications. My focus is on delivering user-friendly, efficient, and high-quality products.
                <br />
                Here are a few technologies I've been working with recently:
                <ul className="about_ul">
                  <li>
                    <span>
                      <ArrowRightIcon className="li_arrow" />
                    </span>
                    Python
                  </li>
                  <li>
                    <span>
                      <ArrowRightIcon className="li_arrow" />
                    </span>
                    Django
                  </li>
                  <li>
                    <span>
                      <ArrowRightIcon className="li_arrow" />
                    </span>
                    JavaScript (ES6+)
                  </li>
                  <li>
                    <span>
                      <ArrowRightIcon className="li_arrow" />
                    </span>
                    React
                  </li>
                  <li>
                    <span>
                      <ArrowRightIcon className="li_arrow" />
                    </span>
                    Next Js
                  </li>
                  <li>
                    <span>
                      <ArrowRightIcon className="li_arrow" />
                    </span>
                    Coolify(for Automated Deployment)
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;

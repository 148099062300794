const Contact = () => {
  return (
    <>
      <section id="contact" className="screen_70">
        <div className="project_external_container">
          <div className="project_header">
            <h2>
              <span>04.</span>Contact
            </h2>
          </div>
        </div>
        <div className="about_img contact_logos">
          {/* <img src={rkr} alt="" /> */}
          <ul class="wrapper">
            {/* <Link to="https://facebook.com/rak11b" target={"_blank"}> */}
            <li class="icon facebook" onClick={() => window.open("https://facebook.com/rak11b", "_blank")}>
              <span class="tooltip">Facebook</span>
              <span>
                <i class="fa fa-facebook-f"> </i>
              </span>
            </li>
            {/* </Link> */}

            <li class="icon instagram" onClick={() => window.open("https://gitlab.com/rak1b", "_blank")}>
              <span class="tooltip">GitLab</span>
              <span>
                <i class="fa fa-gitlab"></i>
              </span>
            </li>
            <li class="icon github" onClick={() => window.open("https://github.com/rak1b", "_blank")}>
              <span class="tooltip">Github</span>
              <span>
                <i class="fa fa-github"></i>
              </span>
            </li>
            <li class="icon youtube" onClick={() => window.open("https://www.linkedin.com/in/rak1b/", "_blank")}>
              <span class="tooltip">Linkedin</span>
              <span>
                <i class="fa fa-linkedin"></i>
              </span>
            </li>
            <li class="icon twitter" onClick={() => window.open("https://facebook.com/rak11b", "_blank")}>
              <span class="tooltip">Twitter</span>
              <span>
                <i class="fa fa-twitter"></i>
              </span>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Contact;

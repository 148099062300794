import "./footer.css";
const Footer = () => {
  return (
    <>
      <div class="footer-container text-center">
        <div class="footer-content text-center">
          <p>&copy; 2024 Md Rakibul Islam. All Rights Reserved.</p>
          {/* <ul class="social-links text-center">
            <li>
              <a href="https://facebook.com/rak11b" target="_blank">
                Facebook
              </a>
            </li>
            <li>
              <a href="https://gitlab.com/rak1b" target="_blank">
                GitLab
              </a>
            </li>
            <li>
              <a href="https://github.com/rak1b" target="_blank">
                GitHub
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/rak1b/" target="_blank">
                LinkedIn
              </a>
            </li>
            <li>
              <a href="https://twitter.com/rak11b" target="_blank">
                Twitter
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
};

export default Footer;

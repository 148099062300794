import FeatureProject from "./Sub_component/project_components/FeatureProject";
// import FeatureProject from "./Sub_component/project_components/ProjectArchive";
import fashverse from "./img/fashVerse.png";
import medicinLib from "./img/medicinLib.png";
import paymentsave from "./img/paymentsave.png";
import kaaruj from "./img/kaaruj.png";
import Menubookbd from "./img/Menubookbd.png";
import PayByLInk from "./img/PayByLInk.png";
import Quickshare from "./img/Quickshare.png";
import royal from "./img/royal.png";
import SingleProject from "./SingleProject";
import { useEffect, useState } from "react";
import { getData, ReturnGet } from "./services/api/Get";
import { BASE_URL } from "./const/Url";
import axios from "axios";

const data = [
  {
    name: "PaymentSave Onboarding",
    desc: "Multifaceted control panel and fully dynamic dashboard ,Integration of Microsoft CRM and various third-party APIs,Utilized Celery for automated task management.",
    tags: ["Python", "Django REST framework", "React", "Tailwind CSS", "Redux"],
    img: paymentsave,
    live: "",
    code: "",
  },
  {
    name: "Inventory Management System",
    desc: "Developed a comprehensive system for inventory, sales, customer, and outlet management, including bulk imports, invoicing, reporting, promotions, and user role management.",
    tags: ["Python", "Django REST framework", "React"],
    img: kaaruj,
    live: "",
    code: "",
  },  {
    name: "PayByLInk",
    desc: "Generate and share payment link to recieve payments",
    tags: ["Python", "Django REST framework", "React"],
    img: PayByLInk,
    live: "",
    code: "",
  },{
    name: "Quickshare",
    desc: "A website where user can share file with password protection but without login ",
    tags: ["Python", "Django", "Js"],
    img: Quickshare,
    live: "",
    code: "",
  },
  {
    name: "FashVerse",
    desc: "A website for fashion and fitness solutions. Users can sign up, log in, create posts, detect face shape from images, use calorie calculators, and many other tools.",
    tags: ["Python", "Django REST framework", "React", "Tailwind CSS", "Redux", "ML algorithms"],
    img: fashverse,
    live: "",
    code: "",
  },
  {
    name: "Menubookbd",
    desc: "A website for restaurant review website with multiple admin panel.",
    tags: ["Python", "Django REST framework", "React", "Redux"],
    img: Menubookbd,
    live: "",
    code: "",
  },
  {
    name: "Medicine Library",
    desc: "A medicine details finder and a forum website.",
    tags: ["Python", "Django", "Bootstrap", "JS"],
    img: medicinLib,
    live: "http://medicinelibrary.rak1b.me/",
    code: "https://github.com/rak1b/MedicineLibrary",
  },
  {
    name: "The Royal Restaurant",
    desc: "Fully responsive static restaurant website.",
    tags: ["HTML", "CSS", "Bootstrap"],
    img: royal,
    live: "http://royalrestaurant.rak1b.me/",
    code: "https://github.com/rak1b/The-Royal-Restaurant",
  },
];

const Projects = () => {
  const [projects, setProjects] = useState(data);
  const [isLoading, setIsLoading] = useState(true);
  const [next_url, set_next_url] = useState(null);

  return (
    <>
      <section id="projects" className="full_screen">
        <div className="project_external_container">
          <div className="project_header">
            <h2>
              <span>02.</span>Things I Have Made
            </h2>
          </div>
          {projects.map((curr, index) => {
            let reverse = (index + 1) % 2 === 0 ? true : false;
            return (
              <div
                className={`${reverse ? "project_container_reverse" : "project_container"}`}
                key={index}
              >
                <SingleProject
                  reverse={reverse}
                  name={curr.name}
                  desc={curr.desc} // Corrected the 'desc' key
                  tagList={curr.tags}
                  img={curr.img} // Corrected the 'img' key
                  live={curr.live} // Corrected the 'live' key
                  code={curr.code} // Corrected the 'code' key
                />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Projects;

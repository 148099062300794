import axios from "axios";
import { BASE_URL } from "../../const/Url";
export const ReturnGet = (setProjects, url) => {
  let URL = `${BASE_URL}/${url}`;

  axios
    .get(URL)
    .then((res) => {
      // console.log(res.data.data.results);
      const result = res.data.data.results;
      setProjects(result);
      return result;
    })
    .catch((err) => {
      const message = JSON.parse(err.request.response).message;
      console.log(err.request.response);
    });
};

export function getData(url, callback, errorcallback) {
  axios
    .get(url)
    .then((res) => {
      //do something
      if (callback != null) {
        callback(res);
      }
    })
    .catch((err) => {
      // catch error
      if (errorcallback != null) {
        errorcallback(err);
      }
    });
}
